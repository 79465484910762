import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Landing from './Landing';
import reportWebVitals from './reportWebVitals';
import Property from './Property';
import ReactGA from "react-ga4";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Vender from './Vender';

const router = createBrowserRouter([
  {
    path: "/",  element:  <Landing></Landing>,
    
  },
  {

    path: "/index.html",  element:  <Landing></Landing>,
  },
  {
    path: "/Property/:id",  element:  <Property></Property>,
  },
  {
    path: "/Vender/",  element:  <Vender></Vender>,
  },
  {
    path: "*",  element:  <Landing></Landing>,
  }
  
]);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
ReactGA.initialize("G-3XXLTV4TK3");
root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
    
    
  </React.StrictMode>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
