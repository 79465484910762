import React, { Component, useEffect, useState } from 'react';
import { render } from 'react-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import { Container, FormControl, FormGroup, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, InputLabel, Link, MenuItem, Select, SelectChangeEvent, Typography, makeStyles, useMediaQuery, withStyles } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import './Landing.css';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import { itemData } from './data';
import Header from './header';


export default function Vender() {


    const [loading, setLoading] = useState(false);

 


    const navigate = useNavigate();





    if (loading) {
        return (<div>Loading</div>)
    }
    else {
        return (


            <Box sx={{ width: "100%" }}>
                <Header></Header>
                <Container>
                
                    <Grid container spacing={20} sx={{ padding: 2 }} >
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}></Grid>
                        <Grid item xs={12} >  
                        <Typography variant="h5" gutterBottom> 
                        Para vender tu propiedad puedes llamar al <Link sx={{ color: 'inherit'  }} href="tel:+56963928601">+569 6392 8601 </Link>    o enviar un mail a  <Link sx={{ color: 'inherit'  }} href="mailto:dmoyaangeler@gmail.com">dmoyaangeler@gmail.com</Link> 
                        </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )
    }
}

