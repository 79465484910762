export const itemData = [

    
     {
        order : 4,
        id:'4',titulo:'CASI NUEVO EN SECTOR POCURO - PROVIDENCIA * 3d/3b'
        ,comuna:'Providencia'
        ,tipo:'depto'
        ,m2:'117'
        ,precio:'11100'
        ,terraza:'13'
        ,m2_totales:'130'
        ,dormitorios:'3'
        ,banhos:'2'
        ,estacionamientos:'2'
        ,bodega:'1'
        ,piso:'2'
        ,orientacion:'NP'
        ,year:'2019'
        ,GComunes:'350000'
        ,Contribuciones:'250000'
        ,otros:'Loggia'
        ,calefaccion:'radiadores agua'
        ,aguacaliente:'central a gas'
        ,descripcion:`Arquitectura y terminaciones de diseño
□        Comprar nuevo [casi] a precio de usado
□        3 dormitorios y 3 baños, con Loggia
□        Dormitorio principal en suite
□        Dormitorios 2 y 3 amplios
□        La cocina da a extensa terraza
□        Orientación principal norte
□        Living a Llewellyn Jones, a poniente
□        2 estacionamientos y 1 bodega
□        Edificio del 2019
□        Piso 2 alto, seguro y protegido
□        EComunes: sala multiuso / piscina / jardín / quincho`

        ,
        fotos: [
            { img: '/out/P4/01.webp' },
            { img: '/out/P4/02.webp' },
            { img: '/out/P4/03.webp' },
            { img: '/out/P4/04.webp' },
            { img: '/out/P4/05.webp' },
            { img: '/out/P4/06.webp' },
            { img: '/out/P4/07.webp' },
            { img: '/out/P4/08.webp' },
            { img: '/out/P4/09.webp' },
            { img: '/out/P4/10.webp' },
            { img: '/out/P4/11.webp' },
            { img: '/out/P4/12.webp' },
            { img: '/out/P4/13.webp' },
            { img: '/out/P4/14.webp' },
            { img: '/out/P4/15.webp' },
            { img: '/out/P4/16.webp' },
            { img: '/out/P4/17.webp' }
 

        ]
    }
    , {
        order : 5,
        id:'5',titulo:'4 DORMITORIOS EN SUITE * KENNEDY□ALDERETE * AÑO 2011',comuna:'Las Condes',tipo:'depto',m2:'202',precio:'17990',terraza:'50',m2_totales:'252',dormitorios:'4',banhos:'5',estacionamientos:'2',bodega:'1',piso:'19',orientacion:'SO/NP',year:'2011',GComunes:'610000',Contribuciones:'890000',
        otros:'Loggia S.Estar',calefaccion:'piso radiante',aguacaliente:'central'
        ,descripcion:`2 Orientaciones Suroriente Hnos. Cabot / Norponiente Kennedy
        □        Living con vista panorámica a la cordillera
        □        Con Sala de Estar y Loggia
        □        Espacios amplios, muy bien iluminado
        □        LAS CONDES Sector Cabot / Alderete / Kennedy
        □        2 estacionamientos y 1 bodega
        □        EComunes: piscina / sala de eventos / gimnasio / lavandería `
        ,
        fotos: [
            { img: '/out/P5/01.webp' },
            { img: '/out/P5/02.webp' },
            { img: '/out/P5/03.webp' },
            { img: '/out/P5/04.webp' },
            { img: '/out/P5/05.webp' },
            { img: '/out/P5/06.webp' },
            { img: '/out/P5/07.webp' },
            { img: '/out/P5/08.webp' },
            { img: '/out/P5/09.webp' },
            { img: '/out/P5/10.webp' },
            { img: '/out/P5/11.webp' },
            { img: '/out/P5/12.webp' },
            { img: '/out/P5/13.webp' },
            { img: '/out/P5/14.webp' },
            { img: '/out/P5/15.webp' },
            { img: '/out/P5/16.webp' }

        ]
    }

    
    , {
        order : 7,
        id:'7',titulo:'DEPARTAMENTO 3d/2b VITACURA-F. de Argüello',
        comuna:'Vitacura',tipo:'depto',m2:'125',precio:'13490',terraza:'24',m2_totales:'149'
        ,dormitorios:'3',banhos:'2',estacionamientos:'2',bodega:'1',piso:'2',orientacion:'NO/SP'
        ,year:'2016',GComunes:'300000',Contribuciones:'400000',otros:'S.Estar',calefaccion:'central',aguacaliente:'central'
        ,descripcion:`2 terrazas grandes
        □        Sala de estar multiuso
        □        Nororiente / Surponiente
        □        Amplia cocina de diseño
        □        2 estacionamientos y 1 bodega
        □        Del 2016, casi nuevo
        □        Piso 2 seguro y protegido
        □        EComunes: sala multiusos / piscina / jardines`

        ,
        fotos: [
      
            { img: '/out/P7/01.webp' },
            { img: '/out/P7/02.webp' },
            { img: '/out/P7/03.webp' },
            { img: '/out/P7/04.webp' },
            { img: '/out/P7/05.webp' },
            { img: '/out/P7/06.webp' },
            { img: '/out/P7/07.webp' },
            { img: '/out/P7/08.webp' },
            { img: '/out/P7/09.webp' },
            { img: '/out/P7/10.webp' },
            { img: '/out/P7/11.webp' },
            { img: '/out/P7/12.webp' },
            { img: '/out/P7/13.webp' },
            { img: '/out/P7/14.webp' },
        ]
    },
    
    {
        order : 0,
        id:'11'
        ,titulo:'3+1 DORMITORIOS PANORÁMICOS * SECTOR LAS LILAS'
        ,comuna:'Providencia'
        ,tipo:'depto',m2:'253'
        ,precio:'19000'
        ,terraza:'20'
        ,m2_totales:'273'
        ,dormitorios:'4'
        ,banhos:'4'
        ,estacionamientos:'2'
        ,bodega:'1'
        ,piso:'16'
        ,orientacion:'TODAS'
        ,year:'1979'
        ,GComunes:'651000'
        ,Contribuciones:'780000'
        ,otros:'S.Estar Loggia'
        ,calefaccion:'radiadores agua'
        ,aguacaliente:'central a gas'
        ,descripcion:`19.000 UF Entrada directa + ascensor servicio
        □	Amplísimos Living-Comedor-Sala de estar
        □	Gran arquitectura años 80, vistas totales
        □	Piso industrial de madera
        □	Cocina y baños de origen`        
        ,fotos: [
            { img: '/out/P11/01.webp' },
            { img: '/out/P11/02.webp' },
            { img: '/out/P11/03.webp' },
            { img: '/out/P11/04.webp' },
            { img: '/out/P11/05.webp' },
            { img: '/out/P11/06.webp' },
            { img: '/out/P11/07.webp' },
            { img: '/out/P11/08.webp' },
            { img: '/out/P11/09.webp' },
            { img: '/out/P11/10.webp' },
            { img: '/out/P11/11.webp' },
            { img: '/out/P11/12.webp' },
            { img: '/out/P11/13.webp' },
            { img: '/out/P11/14.webp' },
            { img: '/out/P11/15.webp' },
            { img: '/out/P11/16.webp' },
            { img: '/out/P11/17.webp' },
            { img: '/out/P11/18.webp' },
            { img: '/out/P11/19.webp' },
            { img: '/out/P11/20.webp' }

        ]

        
    }
    ,
    {
        order : -1,
        id:'12',
        titulo:'GRAN UBICACIÓN Y ARQUITECTURA_60m2 en Av. El Golf '
        ,comuna:'Las Condes'
        ,tipo:'depto'
        ,m2:'60'
        ,precio:'7800'
        ,terraza:''
        ,m2_totales:'60'
        ,dormitorios:'1 o 2'
        ,banhos:'1 o 2'
        ,estacionamientos:'1'
        ,bodega:'1'
        ,piso:'7'
        ,orientacion:'Oriente'
        ,year:'2010'
        ,GComunes:'230000'
        ,Contribuciones:'160'
        
        ,calefaccion:'piso radiante'
        ,aguacaliente:''
        ,descripcion:`Todo a Oriente 
        □   Moderno y de diseño
        □   1 o 2 dormitorios
        □   arquitecto Borja Huidobro
        □   Edificio Premium`       
        ,fotos: [
            { img: '/out/P12/01.webp' },
            { img: '/out/P12/02.webp' },
            { img: '/out/P12/03.webp' },
            { img: '/out/P12/04.webp' },
            { img: '/out/P12/05.webp' },
            { img: '/out/P12/06.webp' },
            { img: '/out/P12/07.webp' },
            { img: '/out/P12/08.webp' },
            { img: '/out/P12/09.webp' },
            { img: '/out/P12/10.webp' },
            { img: '/out/P12/11.webp' }

        ]

        
    }
   ,{
    order:-2
    ,id:'13'
    ,titulo:'60 M2. EN LO MEJOR DE EL GOLF '
    ,comuna:'Las Condes'
    ,tipo:'depto'
    ,m2:'60'
    ,precio:'7900'
    ,terraza:''
    ,m2_totales:'60'
    ,dormitorios:'1 o 2'
    ,banhos:'2'
    ,estacionamientos:'1'
    ,bodega:'1'
    ,piso:'10'
    ,orientacion:'Norponiente'
    ,year:'2010'
    ,GComunes:'230000'
    ,Contribuciones:'160000'
    ,otros:''
    ,calefaccion:'piso radiante '
    ,aguacaliente:''
    ,descripcion:`1 o 2 dormitorios
□	Arquitectura de diseño
□	Edificio Premium
□	Luminoso `
        ,fotos: [
            { img: '/out/P13/01.webp' },
            { img: '/out/P13/02.webp' },
            { img: '/out/P13/03.webp' },
            { img: '/out/P13/04.webp' },
            { img: '/out/P13/05.webp' },
            { img: '/out/P13/06.webp' },
            { img: '/out/P13/07.webp' },
            { img: '/out/P13/08.webp' },
            { img: '/out/P13/09.webp' },
            { img: '/out/P13/10.webp' },
            { img: '/out/P13/11.webp' },
            { img: '/out/P13/12.webp' },
            { img: '/out/P13/13.webp' }

        ]

    
   }
   ,
   {
    order:-3,
    id:'14'
    ,titulo:'EXCELENTES ESPACIOS sector av. El Bosque * PROVIDENCIA  '
    ,comuna:'Providencia'
    ,tipo:'depto'
    ,m2:'129'
    ,precio:'9990'
    ,terraza:'8'
    ,m2_totales:'137'
    ,dormitorios:'3'
    ,banhos:'3'
    ,estacionamientos:'2'
    ,bodega:'1'
    ,piso:'3'
    ,orientacion:'S-O-P'
    ,year:'1993'
    ,GComunes:'220000'
    ,Contribuciones:'320000'
    ,otros:'Loggia'
    ,calefaccion:'eléctrica'
    ,aguacaliente:'calefón'
    ,descripcion:`Cocina remodelada, nueva
    □ Impecable estado, espacioso departamento
    □ Privilegiada iluminación natural
    □ Moderna cocina semi-abierta
    □ 3 Dormitorios independientes, todos con baño
    □ Ubicación y entorno formidables
    □ 2 estacionamientos y 1 bodega`
    ,fotos: [
        { img: '/out/P14/01.webp' },
        { img: '/out/P14/02.webp' },
        { img: '/out/P14/03.webp' },
        { img: '/out/P14/04.webp' },
        { img: '/out/P14/05.webp' },
        { img: '/out/P14/06.webp' },
        { img: '/out/P14/07.webp' },
        { img: '/out/P14/08.webp' },
        { img: '/out/P14/09.webp' },
        { img: '/out/P14/10.webp' },
        { img: '/out/P14/11.webp' },
        { img: '/out/P14/12.webp' },
        { img: '/out/P14/13.webp' },
        { img: '/out/P14/14.webp' },
        { img: '/out/P14/15.webp' },
        { img: '/out/P14/16.webp' },
        { img: '/out/P14/17.webp' },
        { img: '/out/P14/18.webp' },
        { img: '/out/P14/19.webp' },
        { img: '/out/P14/20.webp' },
        { img: '/out/P14/21.webp' },
        { img: '/out/P14/22.webp' },
        { img: '/out/P14/23.webp' },
        { img: '/out/P14/24.webp' }

    ]

   }
   ,
   {
    order:-4,
    id:'15'
    ,titulo:'COMO NUEVO * oportunidad en barrio Las Lilas- Providencia '
    ,comuna:'Providencia'
    ,tipo:'depto'
    ,m2:'104'
    ,precio:'7990'
    ,terraza:'14'
    ,m2_totales:'118'
    ,dormitorios:'4'
    ,banhos:'3'
    ,estacionamientos:'1'
    ,bodega:'1'
    ,piso:'9'
    ,orientacion:'Oriente y Sur'
    ,year:'1982-Remod.'
    ,GComunes:'163000'
    ,Contribuciones:'180000'
    ,otros:'Loggia'
    ,calefaccion:'radiadores    '
    ,aguacaliente:'calefón'
    ,descripcion:`Excelentes vistas 
    □ 2 terrazas
    □ 3+1 dormitorios amplios
    □ Living a Oriente
    □ Dormitorios a Suroriente
    □ Remodelación reciente, casi a estrenar `
    ,fotos: [
    { img: '/out/P15/01.webp' },
    { img: '/out/P15/02.webp' },
    { img: '/out/P15/03.webp' },
    { img: '/out/P15/04.webp' },
    { img: '/out/P15/05.webp' },
    { img: '/out/P15/06.webp' },
    { img: '/out/P15/07.webp' },
    { img: '/out/P15/08.webp' },
    { img: '/out/P15/09.webp' },
    { img: '/out/P15/10.webp' },
    { img: '/out/P15/11.webp' },
    { img: '/out/P15/12.webp' },
    { img: '/out/P15/13.webp' },
    { img: '/out/P15/14.webp' },
]

   }
   ,
   {
    order:-6,

    id:'16'
    ,titulo:' DEPARTAMENTO IMBATIBLE en Plaza LAS LILAS * 4D/4B '
    ,comuna:'Providencia'
    ,tipo:'depto'
    ,m2:'145'
    ,precio:'9900'
    ,terraza:'15'
    ,m2_totales:'145'
    ,dormitorios:'4'
    ,banhos:'4'
    ,estacionamientos:'2'
    ,bodega:'1'
    ,piso:'11'
    ,orientacion:'Norte Poniente'
    ,year:'2009'
    ,GComunes:'300000'
    ,Contribuciones:'313000'
    ,otros:'Loggia   Escritorio   Comedor aparte'
    ,calefaccion:'central a gas'
    ,aguacaliente:'central a gas'
    ,descripcion:`□	145 m2 totales (15m2 2 terrazas cerradas)
    □	Más escritorio Más comedor independiente
    □	Del 2009 moderno y funcional. Excelente estado
    □	Amplísimas cocina y Loggia
    □	Norte+poniente, paneles brise-soleil en fachada`

    
    ,fotos: [
        { img: '/out/P16/01.webp' },
        { img: '/out/P16/02.webp' },
        { img: '/out/P16/03.webp' },
        { img: '/out/P16/04.webp' },
        { img: '/out/P16/05.webp' },
        { img: '/out/P16/06.webp' },
        { img: '/out/P16/07.webp' },
        { img: '/out/P16/08.webp' },
        { img: '/out/P16/09.webp' },
        { img: '/out/P16/10.webp' },
        { img: '/out/P16/11.webp' },
        { img: '/out/P16/12.webp' },
        { img: '/out/P16/13.webp' },
        { img: '/out/P16/14.webp' },
        { img: '/out/P16/15.webp' },
        { img: '/out/P16/16.webp' },
        { img: '/out/P16/17.webp' },
        { img: '/out/P16/18.webp' },
        { img: '/out/P16/19.webp' }
        
        
]

   }   
   ,
   {order:-7,
    id:'17',titulo:'DEL 2022 EN BILBAO/TOBALABA * 3D/3B ',comuna:'Providencia',tipo:'depto',m2:'122',precio:'12582',terraza:'34',m2_totales:'156'
    ,dormitorios:'3',banhos:'3',estacionamientos:'1 opción 2º',bodega:'1 pequeña',piso:'6',orientacion:'NP y NO',year:'2022',GComunes:'345000',Contribuciones:'190000aprox'
    ,otros:'Sala de Estar Loggia 2 Walking Closets',calefaccion:'radiadores eléctricos',aguacaliente:'central'
,descripcion:`□	12.582 UF
□	122 m2 útiles + 34 m2 2 terrazas 
□	Más sala de Estar + Loggia + 2 Walking Closets
□	Como nuevo
□	Norponiente y Nororiente
□	EXCELENTES VISTAS`
    ,fotos: [
        { img: '/out/P17/01.webp' },
        { img: '/out/P17/02.webp' },
        { img: '/out/P17/03.webp' },
        { img: '/out/P17/04.webp' },
        { img: '/out/P17/05.webp' },
        { img: '/out/P17/06.webp' },
        { img: '/out/P17/07.webp' },
        { img: '/out/P17/08.webp' },
        { img: '/out/P17/09.webp' },
        { img: '/out/P17/10.webp' },
        { img: '/out/P17/11.webp' },
        { img: '/out/P17/12.webp' },
        { img: '/out/P17/13.webp' },
        { img: '/out/P17/14.webp' },
        { img: '/out/P17/15.webp' },
        { img: '/out/P17/16.webp' },
        { img: '/out/P17/17.webp' },
        { img: '/out/P17/18.webp' },
        { img: '/out/P17/19.webp' },
        { img: '/out/P17/20.webp' },
        { img: '/out/P17/21.webp' },
        { img: '/out/P17/22.webp' }
        
    ]

}   
,
{order:-8,
    id:'18',
titulo:'PROVIDENCIA <M> BILBAO COMO NUEVO'
,comuna:'Providencia'
,tipo:'depto'
,m2:'103',precio:'10033'
,terraza:'29'
,m2_totales:'132'
,dormitorios:'3'
,banhos:'2'
,estacionamientos:'1 opción 2º'
,bodega:'1 pequeña',piso:'2'
,orientacion:'NP y SP'
,year:'2021'
,GComunes:'250000'
,Contribuciones:'140000aprox'
,otros:'Cocina grande'
,calefaccion:'radiadores eléctricos'
,aguacaliente:'central'
,descripcion:`□ 10.033 UF
□ 103 m2 útiles + 29 m2 en 2 terrazas 
□ Gran Living Comedor en esquina
□ Cocina abierta con isla de trabajo
□ PISO 2. Norponiente y Surponiente
□ Dormitorios 1 y 2 grandes+3er dormitorio
□ +Sala de estar`
,fotos:[
    { img: '/out/P18/01.webp' },
    { img: '/out/P18/02.webp' },
    { img: '/out/P18/03.webp' },
    { img: '/out/P18/04.webp' },
    { img: '/out/P18/05.webp' },
    { img: '/out/P18/06.webp' },
    { img: '/out/P18/07.webp' },
    { img: '/out/P18/08.webp' },
    { img: '/out/P18/09.webp' },
    { img: '/out/P18/10.webp' },
    { img: '/out/P18/11.webp' },
    { img: '/out/P18/12.webp' },
    { img: '/out/P18/13.webp' },
    { img: '/out/P18/14.webp' },
    { img: '/out/P18/15.webp' },
    { img: '/out/P18/16.webp' },
    { img: '/out/P18/17.webp' },
    { img: '/out/P18/18.webp' },
    { img: '/out/P18/19.webp' },
    { img: '/out/P18/20.webp' }
]
}

,

{order:-9,
id:'19',titulo:'EN ESQUINA ORIENTE * VITACURA A PASOS DEL P.ARAUCO'
,comuna:'Vitacura'
,tipo:'depto'
,m2:'120'
,precio:'12250'
,terraza:'19'
,m2_totales:'139'
,dormitorios:'4'
,banhos:'3'
,estacionamientos:'2'
,bodega:'1'
,piso:'2'
,orientacion:'O y SO'
,year:'2008'
,GComunes:'300000'
,Contribuciones:'456000'
,otros:'Loggia'
,calefaccion:'cielo radiante'
,aguacaliente:'central'
,descripcion:`□ Amplios dormitorios, edificio de diseño
□ 139 m2 totales (120m2 útiles +2 terrazas 19m2)
□ Living con terraza L en esquina, PISO 2
□ Del 2009 moderno y funcional. Excelente estado
□ 3+1 dormitorios +3b y Loggia`
,fotos:[
    { img: '/out/P19/01.webp' },
 { img: '/out/P19/02.webp' },
 { img: '/out/P19/03.webp' },
 { img: '/out/P19/04.webp' },
 { img: '/out/P19/05.webp' },
 { img: '/out/P19/06.webp' },
 { img: '/out/P19/07.webp' },
 { img: '/out/P19/08.webp' },
 { img: '/out/P19/09.webp' },
 { img: '/out/P19/10.webp' },
 { img: '/out/P19/11.webp' },
 { img: '/out/P19/12.webp' },
 { img: '/out/P19/13.webp' },
 { img: '/out/P19/14.webp' },
 { img: '/out/P19/15.webp' }

]
},
{order:-10,
    id:'20'
,titulo:'DEL 2.021 @TOBALABA/BILBAO'
,comuna:'Providencia'
,tipo:'depto'
,m2:'65'
,precio:'7113'
,terraza:'8'
,m2_totales:'73'
,dormitorios:'2'
,banhos:'2'
,estacionamientos:'1'
,bodega:'1'
,piso:'2'
,orientacion:'NP y NO'
,year:'2021'
,GComunes:'265000'
,Contribuciones:'155000'
,otros:''
,calefaccion:'radiadores elécricos'
,aguacaliente:'central'
,descripcion:`□ 7.113 UF
□ 65 m2 útiles + 8 m2 terraza
□ 2 dormitorios GRANDES
□ Edificio moderno y nuevo
□ PISO 2. Norponiente y Nororiente
□ MUY RENTABLE INVERSIÓN 
□ Conectividad <M> Bilbao`
,fotos:[
    { img: '/out/P20/01.webp' },
    { img: '/out/P20/02.webp' },
    { img: '/out/P20/03.webp' },
    { img: '/out/P20/04.webp' },
    { img: '/out/P20/05.webp' },
    { img: '/out/P20/06.webp' },
    { img: '/out/P20/07.webp' },
    { img: '/out/P20/08.webp' },
    { img: '/out/P20/09.webp' },
    { img: '/out/P20/10.webp' }

]
}
,
{
    order:-11,
    id:'21'
    ,titulo:'GRAN CASA @CAMINO DE LA LAGUNA-LO BARNECHEA'
    ,comuna:'LoBarnechea'
    ,tipo:'CASA'
    ,m2:'296'
    ,precio:'22500'
    ,terraza:'-'
    ,m2_totales:'296'
    ,dormitorios:'5'
    ,banhos:'5'
    ,estacionamientos:'3',bodega:'2',piso:'2',orientacion:'TODAS',year:'2000',GComunes:'-',Contribuciones:'900000'
    ,otros:'Loggia 2 quinchos 2 bares2 bodegas'
    ,calefaccion:'caldera a gas',aguacaliente:'calefón'
    ,descripcion:`□ Casa de espacios muy amplios
    □ Excelente estado, amplísima cocina
    □ 2 quinchos+terraza+piscina+jardín
    □ 2 salas de estar: piso1 y dormitorio1
    □ 2 bares: 1exterior cubierto+1interior
    □ Sector El Huinganal @Lo Barnechea
    □ Pasaje cerrado `
    ,fotos:  [
        { img: '/out/P21/01.webp' },
        { img: '/out/P21/02.webp' },
        { img: '/out/P21/03.webp' },
        { img: '/out/P21/04.webp' },
        { img: '/out/P21/05.webp' },
        { img: '/out/P21/06.webp' },
        { img: '/out/P21/07.webp' },
        { img: '/out/P21/08.webp' },
        { img: '/out/P21/09.webp' },
        { img: '/out/P21/10.webp' },
        { img: '/out/P21/11.webp' },
        { img: '/out/P21/12.webp' },
        { img: '/out/P21/13.webp' },
        { img: '/out/P21/14.webp' },
        { img: '/out/P21/15.webp' },
        { img: '/out/P21/16.webp' },
        { img: '/out/P21/17.webp' },
        { img: '/out/P21/18.webp' },
        { img: '/out/P21/19.webp' },
        { img: '/out/P21/20.webp' },
        { img: '/out/P21/21.webp' },
        { img: '/out/P21/22.webp' },
        { img: '/out/P21/23.webp' },
        { img: '/out/P21/24.webp' },
        { img: '/out/P21/25.webp' },
        { img: '/out/P21/26.webp' },
        { img: '/out/P21/27.webp' },
        { img: '/out/P21/28.webp' },
        { img: '/out/P21/29.webp' }        

    ]
}
]