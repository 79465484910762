import React, { Component, useEffect, useState } from 'react';
import { render } from 'react-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import { BottomNavigation, BottomNavigationAction, Container, FormControl, FormGroup, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, InputLabel, Link, MenuItem, Select, SelectChangeEvent, SpeedDial, SpeedDialAction, SpeedDialIcon, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import './Landing.css';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import HomeIcon from '@mui/icons-material/Home';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';

import Stack from '@mui/material/Stack';

import CallIcon from '@mui/icons-material/Call';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { itemData } from './data';
import { useParams } from 'react-router-dom';
import Header from './header';
import { WifiCalling3 } from '@mui/icons-material';


export default function Property() {

    const [loading, setLoading] = useState(false);
    let theme = createTheme();
    

    let  matchDownMd = useMediaQuery(theme.breakpoints.down('md'));



    const { id } = useParams()

    
    theme = responsiveFontSizes(theme);

    //let  propiedad = itemData[Number.parseInt(id!) - 1]

    //let propiedad =   itemData.find(item => Number.parseInt(item.id!) === (Number.parseInt(id!) - 1));

    let propiedad =   itemData.find(item => Number.parseInt(item.id!)===Number.parseInt(id!));

    if (propiedad === undefined) {
        throw new TypeError('The value was promised to always be there!');
      }

    const actions = [
        { icon: <Link sx={{ color: 'inherit'  }} href="tel:+56963928601"><WifiCalling3  sx={{ color: 'inherit' , maxWidth: { xs: 50, md: 170 } }}/></Link>, name: 'Llámame' },

        { icon: <Link sx={{ color: 'inherit'}} href={"https://wa.me/+56963928601/?text=Hola quiero consultar por la propiedad : " + propiedad.titulo}><WhatsAppIcon  style={{ color: 'green' }} /> </Link>, name: 'Envíame un Whatsapp' },
    ];
    

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        window.scrollTo(0, 0)

    }, [])

    if (loading) {
        return (<div>Loading</div>)
    }
    else {
        return (
            <div>
                <ThemeProvider theme={theme}>
                    <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: 'fixed', bottom: 10, right: 10, '& .MuiFab-primary': { width: 110, height: 110 } 
                        
                    
                    }}
                        
                        icon={
                            <Box sx={{ display: "flex" }}>
                                <Typography> Agenda Tu Visita </Typography>
                            </Box>
                        }
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                sx={{width: 70, height: 70 }}
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                            />
                        ))}
                    </SpeedDial>
                </ThemeProvider>

                <Header></Header>
                <Container>
                    <Grid container spacing={5} sx={{ pt: 20, pl: 2, pr: 2 }} >
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom> {Number( propiedad.precio).toLocaleString('es-CL')} UF - {propiedad.titulo}  </Typography>


                            <Grid container spacing={0} sx={{ pt: 0, pl: 0, pr: 0 }} >
                            <Typography variant="body2" gutterBottom>{propiedad.descripcion}
                            <br></br>
<b>Comuna</b>: {propiedad.comuna}  
<b> Piso</b>: {propiedad.piso}  
<b> Tipo</b>: {propiedad.tipo}    
<b> Orientación</b> : {propiedad.orientacion}  
<b> M2 Útiles</b>: {propiedad.m2}  
<b> Año</b>: {propiedad.year}  
<b> M2 Terraza</b>: {propiedad.terraza}   
<b> Gastos Comunes</b>: {Number( propiedad.GComunes).toLocaleString('es-CL')}  
<b> M2 Totales</b>: {propiedad.m2_totales}  
<b> Contribuciones</b>: {Number( propiedad.Contribuciones).toLocaleString('es-CL')}  
<b> Dormitorios</b>: {propiedad.dormitorios}  
<b> Otros</b>: {propiedad.otros}  
<b> Baños</b>: {propiedad.banhos}  
<b> Calefacción</b>: {propiedad.calefaccion}  
<b> Estacionamientos</b>: {propiedad.estacionamientos}  
<b> Agua Caliente</b>: {propiedad.aguacaliente}  
<b> Bodega</b>: {propiedad.bodega}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <ImageList sx={{
                                mb: 8,
                                
                              
                            }} 
                            cols={matchDownMd ? 1 : 2}
                             >
                                {propiedad.fotos.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <img
                                            srcSet={`https://s3.amazonaws.com/angeler.cl${item.img}`}
                                            src={`https://s3.amazonaws.com/angeler.cl${item.img}`}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>
                    </Grid>
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation showLabels   >

                            <BottomNavigationAction label="Home" icon={<Link sx={{ color: 'inherit' }} href="/">  <HomeIcon /></Link>} />
                        </BottomNavigation>
                    </Paper>
                </Container>

            </div>
        )
    }
}

