import React, { Component, useEffect, useState } from 'react';
import { render } from 'react-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import { Container, FormControl, FormGroup, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, InputLabel, Link, MenuItem, Select, SelectChangeEvent, makeStyles, useMediaQuery, withStyles } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import './Landing.css';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import { itemData } from './data';
import Header from './header';


export default function Landing() {


    const [loading, setLoading] = useState(false);

    const [imgcol, setImgCol] = useState(1);

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {

        if (window.innerWidth < 2800) {
            //setImgCol(2);
        }
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    const navigate = useNavigate();

    const navigateTo = (url: string) => {
        return (event: React.MouseEvent) => {
            navigate(url);
            event.preventDefault();
        }
    }

    const newitemData = itemData.sort((a,b)=> (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0  ))


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    if (loading) {
        return (<div>Loading</div>)
    }
    else {
        return (


            <Box sx={{ width: "100%" }}>
                <Header></Header>
                <Container>

                    <Grid container spacing={20} sx={{ padding: 2 }} >
                        <Grid item xs={12} >  </Grid>
                        <Grid item xs={12}>
                            <ImageList sx={{
                                mb: 8,
                                gridTemplateColumns:
                                    'repeat(auto-fill, minmax(280px, 1fr))!important',
                            }} >
                                {newitemData.map((item) => (
                                    <ImageListItem key={item.fotos[0].img}>


                                        <img
                                            srcSet={`https://s3.amazonaws.com/angeler.cl${item.fotos[0].img}`}
                                            src={`https://s3.amazonaws.com/angeler.cl${item.fotos[0].img}`}
                                            alt={item.titulo}
                                            loading="lazy"
                                            style={{ cursor: 'pointer' }}
                                            onClick={navigateTo('./Property/' + item.id)}
                                        />

                                        <ImageListItemBar
                                            title={item.comuna + " " + Number(item.precio).toLocaleString('es-CL') + "UF"}
                                            subtitle={Number(item.m2_totales).toLocaleString('es-CL') + " MT2"}
                                            position="below"
                                            sx={{
                                                "& .MuiImageListItemBar-title": { color: "black" }, //styles for title
                                                "& .MuiImageListItemBar-subtitle": { color: "black" }, //styles for subtitle
                                            }}

                                            actionIcon={
                                                <Link href={'./Property/' + item.id}>
                                                    <IconButton
                                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                        aria-label={`info about ${item.precio} ${item.comuna}`}
                                                    >
                                                    </IconButton>
                                                </Link>
                                            }
                                        />



                                    </ImageListItem>

                                ))}
                            </ImageList>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )
    }
}

