import { Box, Card, Container, Grid, Link, ThemeProvider, Typography, createTheme } from "@mui/material";
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTheme } from '@mui/material/styles';

import CallIcon from '@mui/icons-material/Call';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate } from "react-router-dom";

export default function Header() {

    /*
                        <Grid xs={6} md={1} > <Link  href={"https://wa.me/+56963928601/?text="}> <WhatsAppIcon sx= {{ width: 30, height: 30 }}  ></WhatsAppIcon> </Link>
                        
                        </Grid>
    */

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const theme = createTheme();

    const navigate = useNavigate();

    const navigateTo = (url: string) => {
        return (event: React.MouseEvent) => {
            navigate(url);
            event.preventDefault();
        }
    }


    theme.typography.h6 = {
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.0rem',
          },
          
      [theme.breakpoints.up('md')]: {
        fontSize: '1.6rem',
      }
    };

    return (
<ThemeProvider theme={theme}>
        <Box
            sx={{



                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '100',
                position: 'fixed',
                width: "100%",
                top: 0,
                margin: 'auto',
                textAlign: 'center',
                zIndex: 'modal',
                color: '#00000099',


            }}
        >
            <Container>
                <Grid container sx={{ padding: 1, background: 'white' }} >
                    <Grid xs={12} md={5} sx={{ textAlign: 'center', zIndex: 'modal', color: '#00000099' }}  >
                     <Link sx={{ color: 'inherit' }} href="/">  
                        <Box
                         
                            component="img"
                            sx={{ maxWidth: { xs: 350, md: 400 }, }}
                            alt="daniel-moya-angeler"
                            style={{ cursor: 'pointer' }}
                            src="https://s3.amazonaws.com/angeler.cl/out/logo.png"
                        />
                        
                        </Link>
                    </Grid>
                    <Grid xs={4} md={2}  sx={{ display: 'flex', alignItems: 'center'   }} >
                        <Box   >
                        <Typography variant="h6"   gutterBottom>Las Condes<br />Vitacura<br />Providencia</Typography>
                        </Box>
                    </Grid>
                    <Grid xs={4} md={2} sx={{ background: 'blue', color: "white" , display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" gutterBottom>  <Link sx={{ background: 'blue', color: "white",textDecoration: 'none'  }}  href='/Vender'   >¿Quieres  vender tu propiedad?</Link></Typography>
                    </Grid>
                    <Grid xs={4} md={3}   >
                        <Grid container  >
                            <Grid xs={12}    >
                                <Typography variant="h6" gutterBottom> <Link sx={{ color: 'inherit'  }} href="tel:+56963928601">+569 6392 8601</Link></Typography>
                            </Grid>
                            <Grid xs={12}    >
                                

                                <Link sx={{ color: 'inherit' }} href={'https://www.instagram.com/angeler_propiedades/'}>  
                        <Box
                         
                            component="img"
                            sx={{ maxWidth: { xs: 50, md: 70 }, }}
                            
                            style={{ cursor: 'pointer' }}
                            src="https://s3.amazonaws.com/angeler.cl/out/Instagram-Icon.png"
                        />
                        
                        </Link>
                            </Grid>

                        </Grid>

                    </Grid>


                </Grid>

                <Box
                    sx={{

                        width: '100%',
                        height: '2rem',
                        textAlign: 'center',
                        zIndex: 'modal',
                        background: 'linear-gradient(rgba(255,255,255,1),  rgba(255,255,255,0)  )'
                    }}
                >
                </Box>
            </Container>

        </Box>
        </ThemeProvider>


    )




}